import React from 'react';

export default function Keamanan() {
  return (
    <section className="py-6" id="mengapa">
      <div className="container-lg">
        <div className="row flex-center">
          <div className="col-auto text-center my-2"></div>
        </div>
        <div className="row">
          <h2 className="text-center text-uppercase fw-bold position-relative mb-5">
            Fasilitas
          </h2>
          <div className="col-12 col-lg-4 mb-4">
            <div className="card bg-soft-danger" style={{ minHeight: 240 }}>
              <div className="card-body p-4">
                <div className="d-flex">
                  <i>
                    <img
                      src="assets/img/icons/security.svg"
                      alt="icon-security"
                    />
                  </i>
                  <h5 className="ms-2 card-title text-left fw-bold mb-3 pt-1">
                    Aman
                  </h5>
                </div>
                <p className="card-text text-left">
                  Keamanan di Azelea Student Living dilengkapi dengan cctv dan
                  di jaga 24 jam, kamu gk perlu khawatir tinggal disini.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4 mb-4">
            <div className="card bg-soft-danger" style={{ minHeight: 240 }}>
              <div className="card-body p-4">
                <div className="d-flex">
                  <i>
                    <img
                      style={{ height: 30 }}
                      src="assets/img/icons/parking.svg"
                      alt="icon-security"
                    />
                  </i>
                  <h5 className="ms-2 card-title text-left fw-bold mb-3 pt-1">
                    Parkir Luas
                  </h5>
                </div>
                <p className="card-text text-left">
                  Parkiran luas, cukup untuk kamu yang bawa kendaraan roda 4
                  maupun roda 2! gk pakai ribet!!
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4 mb-4">
            <div className="card bg-soft-danger" style={{ minHeight: 240 }}>
              <div className="card-body p-4">
                <div className="d-flex">
                  <i>
                    <img
                      style={{ height: 30 }}
                      src="assets/img/icons/building.svg"
                      alt="icon-security"
                    />
                  </i>
                  <h5 className="ms-2 card-title text-left fw-bold mb-3 pt-1">
                    Fasilitas Lengkap
                  </h5>
                </div>
                <p className="card-text text-left">
                  Fasilitas di Azelea cukup lengkap loh, mulai dari AC, Air
                  Panas, Wifi 100 mbps, Kasur, Lemari, Meja Belajar, Rak Buku.
                  Pokoknya tinggal datang bawa baju santai kayak dirumah
                  sendiri.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
