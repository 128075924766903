import React from 'react';

export default function Galeri() {
  return (
    <section className="py-5" id="galeryi">
      <div className="container-lg bg-soft-danger p-5 p-md-5 p-xl-7 rounded-3">
        <div className="row flex-center  ">
          <div className="col-12">
            <h2 className=" fw-bold">Intip Galery Kami!</h2>
          </div>
        </div>
        <div
          id="carouselExampleIndicators"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <ol class="carousel-indicators " style={{ margin: '-40px' }}>
            <li
              class="active"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to={0}
            ></li>
            <li
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to={1}
            ></li>
            <li
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to={2}
            ></li>
          </ol>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className="row h-100">
                <div class="col-md-4 mb-3 mb-md-0">
                  <div class="card h-100 py-3">
                    <div class="card-body my-2">
                      <img
                        class="img-fluid me-3 me-md-2 me-lg-3"
                        src="assets/img/testimoni/testi-1.jpg"
                        width="100%"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-4 mb-3 mb-md-0">
                  <div class="card h-100 py-3">
                    <div class="card-body my-2">
                      <img
                        class="img-fluid me-3 me-md-2 me-lg-3"
                        src="assets/img/testimoni/testi-2.jpg"
                        width="100%"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-4 mb-3 mb-md-0">
                  <div class="card h-100 py-3">
                    <div class="card-body my-2">
                      <img
                        class="img-fluid me-3 me-md-2 me-lg-3"
                        src="assets/img/testimoni/testi-3.jpg"
                        width="100%"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="row h-100">
                <div class="col-md-4 mb-3 mb-md-0">
                  <div class="card h-100 py-3">
                    <div class="card-body my-2">
                      <img
                        class="img-fluid me-3 me-md-2 me-lg-3"
                        src="assets/img/testimoni/testi-4.jpg"
                        width="100%"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-4 mb-3 mb-md-0">
                  <div class="card h-100 py-3">
                    <div class="card-body my-2">
                      <img
                        class="img-fluid me-3 me-md-2 me-lg-3"
                        src="assets/img/testimoni/testi-5.jpg"
                        width="100%"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-4 mb-3 mb-md-0">
                  <div class="card h-100 py-3">
                    <div class="card-body my-2">
                      <img
                        class="img-fluid me-3 me-md-2 me-lg-3"
                        src="assets/img/testimoni/testi-6.jpg"
                        width="100%"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="row h-100">
                <div class="col-md-4 mb-3 mb-md-0">
                  <div class="card h-100 py-3">
                    <div class="card-body my-2">
                      <img
                        class="img-fluid me-3 me-md-2 me-lg-3"
                        src="assets/img/testimoni/testi-7.jpg"
                        width="100%"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-4 mb-3 mb-md-0">
                  <div class="card h-100 py-3">
                    <div class="card-body my-2">
                      <img
                        class="img-fluid me-3 me-md-2 me-lg-3"
                        src="assets/img/testimoni/testi-8.jpg"
                        width="100%"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-4 mb-3 mb-md-0">
                  <div class="card h-100 py-3">
                    <div class="card-body my-2">
                      <img
                        class="img-fluid me-3 me-md-2 me-lg-3"
                        src="assets/img/testimoni/testi-9.jpg"
                        width="100%"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
