import React from 'react';
import { Link } from 'react-router-dom';

export default function Headerr() {
  const url = window.location.pathname;
  return (
    <div className="bg-white">
      <nav className="navbar navbar-expand-lg navbar-light  fixed-top bg-white">
        <div className="container bg-white p-0">
          <Link class="navbar-brand" to="/">
            <img
              className="img-fluid"
              src="assets/img/icons/logo.png"
              alt="logo-icon"
              width={120}
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0 ">
              <li className="nav-item">
                <a
                  className="nav-link nav_top scrollto fw-medium"
                  href={`${url === '/peraturan' ? '/' : '#tentang'}`}
                >
                  Tentang
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link nav_top scrollto fw-medium"
                  href={`${url === '/peraturan' ? '/' : '#mengapa'}`}
                >
                  Fasilitas
                </a>
              </li>

              <li className="nav-item">
                <a
                  className="nav-link nav_top scrollto fw-medium"
                  href={`${url === '/peraturan' ? '/' : '#galeryi'}`}
                >
                  Galery
                </a>
              </li>
              <li className="nav-item">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`nav-link nav_top scrollto fw-medium  ${
                    url === '/peraturan' ? 'active' : ''
                  }`}
                  href="/peraturan"
                >
                  Peraturan
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}
