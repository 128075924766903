import React, { useEffect } from 'react';
import Headerr from '../components/Headerr';
import Footer from '../components/Footer';
import Kewajiban from '../components/home/Kewajiban';
const Peraturan = () => {
  useEffect(() => {
    document.title = 'Azalea Student Living | Peraturan';
  }, []);

  return (
    <>
      <Headerr />
      <Kewajiban />
      <Footer></Footer>
    </>
  );
};

export default Peraturan;
