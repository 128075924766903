import React from 'react';
import '../../assets/css/testimonial.css';

export default function Penawaran() {
  return (
    <div>
      <section className="py-5" id="galeryi">
        <div className="container-lg bg-soft-danger p-5 p-md-5 p-xl-7 rounded-3 testimonials">
          <div className="row flex-center  ">
            <div className="col-12 text-center">
              <h2 className=" fw-bold mb-2">Testimoni</h2>
            </div>
          </div>
          <div className="col-12 col-lg-12  mb-12">
            <div
              id="carouselExampleControls"
              class="carousel w-auto slide"
              data-bs-ride="carousel"
              data-bs-touch={'true'}
            >
              {/* Carousel indicators */}
              <div className="carousel-indicators">
                <button
                  type="button"
                  data-bs-target="#carouselExampleControls"
                  data-bs-slide-to={0}
                  className="active"
                  aria-current="true"
                  aria-label="Slide 1"
                />
                <button
                  type="button"
                  data-bs-target="#carouselExampleControls"
                  data-bs-slide-to={1}
                  aria-label="Slide 2"
                />
                <button
                  type="button"
                  data-bs-target="#carouselExampleControls"
                  data-bs-slide-to={2}
                  aria-label="Slide 3"
                />
              </div>
              {/* </div> */}

              {/* Wrapper for carousel items */}
              <div class="carousel-inner ">
                <div className="carousel-item active">
                  <div className="img-box">
                    <img
                      src="assets/img/illustrations/testi-1.jpg"
                      alt="foto testimonial 1"
                    />
                  </div>
                  <p className="text-dark pt-3">
                    "Gak salah pilih dulu semasa kuliah ngekos di kost Azalea.
                    Kamarnya luas, fasilitasnya lengkap, nyaman, dan aman. Udah
                    berasa tidur di rumah sendiri. Udah nggak kepikiran buat
                    pindah-pindah kos lagi sampai lulus kuliah, karena lokasinya
                    dekat dengan kampus dan banyak tempat jual makanan di dekat
                    kosan! Kos Azalea memang Terbaik."
                  </p>
                  <p className="overview">
                    <b>Rosi Damayanti</b>
                  </p>
                </div>

                <div className="carousel-item">
                  <div className="img-box">
                    <img
                      src="assets/img/illustrations/testi-3.jpg"
                      alt="image_testi_3"
                    />
                  </div>
                  <p className="text-dark pt-3">
                    "Kos Azalea lokasinya sangat strategis, dekat dengan kampus,
                    banyak tempat makan, dan cafe untuk nugas yang siap nemenin
                    24 jam! Kos Azalea juga nyaman banget dengan fasilitas
                    interior yang cantik dan tempat penyimpanan yang serbaguna.
                    Kamarnya juga super besar, keamanannya terjaga, dan yang
                    pasti parkiran yang juga luas! Betah banget tinggal di Kos
                    Azalea karena penjaga nya juga super ramah!🤗"
                  </p>
                  <p className="overview">
                    <b>Vivian</b>
                  </p>
                </div>
                <div className="carousel-item">
                  <div className="img-box">
                    <img
                      src="assets/img/illustrations/testi-2.jpg"
                      alt="image_testi_2"
                    />
                  </div>
                  <p className="text-dark pt-3">
                    "Tempatnya luas, bersih, dan semua furniture juga bagus....
                    Nyaman, ada dapur bersih juga… Mbak dan mas penjaganya jg
                    baik2.... ☺️☺️☺️🙏🏻🙌🏻 . Tempat kost strategis dekat sama
                    tempat2 makan."
                  </p>
                  <p className="overview">
                    <b>Corneli Ajeng</b>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
