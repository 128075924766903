import $ from 'jquery';

$(function () {
  $(document).on('click', '.nav_top', function () {
    $('.nav_top').addClass('fw-medium');
    $('.nav_top').removeClass('fw-bold');
    $('.nav_top').removeClass('active');
    $(this).addClass('fw-bold active');
  });

  const select = (el, all = false) => {
    if (typeof el != 'undefined') {
      el = el.trim();
      if (all) {
        return [...document.querySelectorAll(el)];
      } else {
        return document.querySelector(el);
      }
    }
  };

  /**
   * Easy event listener function
   */
  // eslint-disable-next-line no-unused-vars
  const on = (type, el, listener, all = false) => {
    let selectEl = select(el, all);
    if (selectEl) {
      if (all) {
        selectEl.forEach((e) => e.addEventListener(type, listener));
      } else {
        selectEl.addEventListener(type, listener);
      }
    }
  };

  /**
   * Easy on scroll event listener
   */
  const onscroll = (el, listener) => {
    el.addEventListener('scroll', listener);
  };

  let navbarlinks = select('#navbar .scrollto', true);
  const navbarlinksActive = () => {
    let position = window.scrollY + 200;
    navbarlinks.forEach((navbarlink) => {
      if (!navbarlink.hash) return;
      let section = select(navbarlink.hash);
      if (!section) return;
      if (
        position >= section.offsetTop &&
        position <= section.offsetTop + section.offsetHeight
      ) {
        navbarlink.classList.add('fw-bold');
        navbarlink.classList.add('active');
      } else {
        navbarlink.classList.remove('fw-bold');
        navbarlink.classList.remove('active');
      }
    });
  };
  window.addEventListener('load', navbarlinksActive);
  onscroll(document, navbarlinksActive);
});
