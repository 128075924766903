import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
  function openWA() {
    window.open(
      'https://api.whatsapp.com/send?phone=628127130232&text=Hi Kak %20*Azalea Student Living* Apakah ada kamar kosong?'
    );
  }
  function openIG() {
    window.open(' https://www.instagram.com/azalea_student_living/');
  }
  return (
    <section className="py-6 pb-0 pt-0">
      <div className="container">
        <div className="row py-5 px-6 justify-content-sm-between text-center text-md-start bg-soft-danger">
          <div className="col-md-6">
            <h1 className="fw-bold">
              Punya pertanyaan lain?
              <br className="d-block d-lg-none d-xl-block" />
              Yuk, hubungi kami!
            </h1>
            <p>
              Gak usah bimbang, malu atau ragu. Hubungi kami di nomor whatsapp
              tertera, pasti kami jawab!
            </p>
          </div>
          <div className="col-md-6 text-lg-end d-flex align-items-center justify-content-end">
            <Link
              className="btn btn-light rounded-pill shadow fw-bold"
              onClick={openWA}
              // role="button"
            >
              Konsultasi Sekarang
              <svg
                className="bi bi-arrow-right"
                xmlns="http://www.w3.org/2000/svg"
                width={20}
                height={20}
                fill="#9C69E2"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                />
              </svg>
            </Link>
          </div>
        </div>
        <div className="row justify-content-lg-around mt-6">
          <div className="col-12 col-sm-12 col-lg-3 mb-2 order-0 order-sm-0">
            <div>
              <h6 className="lh-lg fw-bold text-left">Alamat</h6>
              <p className="mt-1">
                Blk. A No.16, Pogung Kidul, Sinduadi, Kec. Mlati,
                <br />
                Kabupaten Sleman,
                <br /> Daerah Istimewa Yogyakarta 55284
              </p>
              <p>0812-7130-232 (WhatsApp) </p>
            </div>
          </div>

          <div className="col-10 col-sm-6 col-lg-6 col-mb-6 order-1 order-sm-1 text-left d-flex justify-content-left justify-content-lg-center -mt-2 mb-4">
            <div className="w-lg-50">
              <h6 className="lh-lg fw-bold mb-1 mb-lg-2 text-left">
                Peta Lokasi
              </h6>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.google.com/maps/place/Azalea+student+living/@-7.7608689,110.3745934,17z/data=!3m1!4b1!4m5!3m4!1s0x2e7a59667554890f:0xe46b6933bb3679e!8m2!3d-7.7608946!4d110.3767749 "
              >
                <img
                  className="img-fluid mt-2"
                  src="assets/img/illustrations/maps.jpg"
                  alt="hallo"
                />
              </a>
            </div>
          </div>
          {/* <div className="col-6 col-sm-4 col-lg-3 mb-3 order-3 order-sm-2"></div> */}
          <div className="col-12 col-sm-4 col-lg-3 mb-1 order-2 order-sm-3">
            <h6 className="lh-lg fw-bold mb-1 mb-lg-2">Social Media </h6>
            <ul className="list-unstyled mb-md-4 mb-lg-0">
              <li className="list-inline-item">
                <Link
                  className="text-dark fs--1 text-decoration-none"
                  onClick={openWA}
                  target="_blank"
                >
                  <img
                    className="img-fluid sosmed"
                    src="assets/img/icons/f.png"
                    width={40}
                    alt="logo sosmed"
                  />
                </Link>
              </li>
              <li className="list-inline-item">
                <Link
                  className="text-dark fs--1 text-decoration-none"
                  onClick={openIG}
                >
                  <img
                    className="img-fluid sosmed"
                    src="assets/img/icons/i.png"
                    width={40}
                    alt="logo sosmed"
                  />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* end of .container*/}
    </section>
  );
}
