import React from 'react';

export default function Kewajiban() {
  return (
    <div className="w-100 mt-5" id="pakai">
      <section className="py-6 pt-0">
        <div className="container-lg">
          <div className="row flex-center mb-2">
            <div className="col-auto text-center my-4">
              <h1 className="mb-4 fw-bold">KEWAJIBAN PENYEWA</h1>
              <p>
                Sebagai penyewa kalian pasti tau kan kalau ada peraturan -
                peraturan yang wajib di ikutin, <br />
                berikut beberapa peraturan yang ada di Azalea Student Living
                untuk menjaga keharmonisan satu sama lain dan kenyamanan
                bersama.
              </p>
            </div>
          </div>
          <div className="row  justify-content-center">
            <div className="col-lg-2"></div>
            <div className="col-12 col-lg-10 text-center">
              <ul className="list-group list-group-flush col-12 col-lg-10 ">
                <li className="list-group-item ">
                  <div className="row ">
                    <div className="d-flex align-items-center">
                      <div className="card-body text-md-start text-center">
                        <h4 className="card-title">A.</h4>
                        <p className="card-text">
                          Memberikan informasi Nama dan No Telpon Keluarga
                          Penanggung Jawab (Orang Tua Penyewa) yang dapat
                          dihubungi.
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="list-group-item ">
                  <div className="row ">
                    <div className="d-flex align-items-center">
                      <div className="card-body text-md-start text-center">
                        <h4 className="card-title">B.</h4>
                        <p className="card-text">
                          Tidak mempergunakan Kamar Sewa untuk kegiatan atau
                          tindakan yang bertentangan dengan Hukum Yang Berlaku
                          serta norma.
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="list-group-item ">
                  <div className="row ">
                    <div className="d-flex align-items-center">
                      <div className="card-body text-md-start text-center">
                        <h4 className="card-title">C.</h4>
                        <p className="card-text">
                          Tidak melakukan tindakan yang dapat menimbulkan
                          kerusakan atau keributan, gangguan pada Rumah Kos,
                          Pengelola Kos, penyewa lain serta tetangga sekitar.
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="list-group-item ">
                  <div className="row ">
                    <div className="d-flex align-items-center">
                      <div className="card-body text-md-start text-center">
                        <h4 className="card-title">D.</h4>
                        <p className="card-text">
                          Tidak mempergunakan narkoba, minuman keras atau barang
                          terlarang lain dan tidak menyimpan, menimbun,
                          mengizinkan atau membiarkan disimpan, narkoba atau
                          sejenisnya, senjata api, amunisi, bahan peledak,
                          bensin, minyak tanah, bahan bakar atau barang
                          berbahaya lainnya di dalam Kamar Kos dan Rumah Kos.
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="list-group-item ">
                  <div className="row ">
                    <div className="d-flex align-items-center">
                      <div className="card-body text-md-start text-center">
                        <h4 className="card-title">E.</h4>
                        <p className="card-text">
                          Memelihara semua bagian dalam Kamar Kos termasuk
                          perkakas dan barang yang melekat lainnya yang dimiliki
                          Pemilik Kos serta mengganti biaya perbaikan yang rusak
                          karena kelalaian Penyewa atau tamunya.
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="list-group-item ">
                  <div className="row ">
                    <div className="d-flex align-items-center">
                      <div className="card-body text-md-start text-center">
                        <h4 className="card-title">F.</h4>
                        <p className="card-text">
                          Tidak mengizinkan tamu dengan gender laki-laki masuk
                          kamar, tidak diperkenankan tamu menginap tanpa izin
                          pengelola Kos.
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="list-group-item ">
                  <div className="row ">
                    <div className="d-flex align-items-center">
                      <div className="card-body text-md-start text-center">
                        <h4 className="card-title">G.</h4>
                        <p className="card-text">
                          Aktivitas masak memasak dilakukan di fasilitas dapur
                          yang telah disediakan dan tidak diperkenankan memasak
                          di dalam kamar.
                        </p>
                      </div>
                    </div>
                  </div>
                </li>

                <li className="list-group-item ">
                  <div className="row ">
                    <div className="d-flex align-items-center">
                      <div className="card-body text-md-start text-center">
                        <h4 className="card-title">H.</h4>
                        <p className="card-text">
                          Tidak memelihara binatang peliharaan ke dalam Kamar
                          Kos atau lingkungan Rumah Kos.
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
                {/* <li className="list-group-item ">
                  <div className="row ">
                    <div className="d-flex align-items-center">
                      <div className="card-body text-md-start text-center">
                        <h4 className="card-title">I.</h4>
                        <p className="card-text">
                          Aktivitas masak memasak dilakukan di fasilitas dapur
                          yang telah disediakan dan tidak diperkenankan memasak
                          di dalam kamar.
                        </p>
                      </div>
                    </div>
                  </div>
                </li> */}
              </ul>
            </div>
            <div className="col-12 col-lg-6"></div>
          </div>
        </div>
      </section>
      {/* <section> close ============================*/}
      {/* ============================================*/}
    </div>
  );
}
