import React from 'react';
import { Link } from 'react-router-dom';

export default function Hero() {
  function openTab() {
    window.open(
      'https://api.whatsapp.com/send?phone=628127130232&text=Hi Kak %20*Azalea Student Living* Apakah ada kamar kosong?'
    );
  }
  return (
    <div className="w-100" id="tentang">
      <section className="py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5 col-lg-7 order-md-1 pt-lg-7 pt-4  ">
              <img
                className="img-fluid"
                src="assets/img/illustrations/hero-header-2.JPG"
                alt="azalea student living"
              />
            </div>
            <div className="col-md-7 col-lg-5 text-center text-md-start pt-5 pt-md-7">
              <h1 className="mb-4 display-2 fw-bold">
                Azalea Student Living{' '}
                <br className="d-block d-lg-none d-xl-block" />
              </h1>
              <p className="mt-3 mb-6">
                Cari kamar kost khusus putri yang nyaman, aman dan bersih? gak
                usah bingung, Azalea Student Living memberimu kenyamanan seperti
                di rumah sendiri! Lokasi strategis dekat kampus Universitas
                Gadjah Mada “UGM”.
              </p>
              <Link
                className="btn btn-lg btn-success rounded-pill"
                onClick={openTab}
                // rel="noopener noreferrer"
              >
                Pesan Sekarang
              </Link>
            </div>
          </div>
        </div>
        {/* end of .container*/}
      </section>
      <section className="py-4">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="card mb-3 bg-soft-danger rounded-3">
                <div className="row g-0 align-items-center">
                  <div className="col-md-5 col-lg-6 text-md-center">
                    <img
                      className="img-fluid"
                      src="assets/img/illustrations/about.png"
                      alt="image_about"
                    />
                  </div>
                  <div className="col-md-7 col-lg-6 px-md-2 px-xl-6 text-center text-md-start">
                    <div className="card-body px-4 py-5 p-lg-3 p-md-4">
                      <h1 className="mb-4 fw-bold">
                        Kamar Luas <br className="d-md-none d-xxl-block" />
                        Perabot Lengkap{' '}
                      </h1>
                      <p className="card-text">
                        Kamar luas perabot lengkap, kamu tinggal datang bawa
                        koper dan tata baju kamu, semudah itu!!{' '}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end of .container*/}
      </section>
    </div>
  );
}
