import React, { useEffect } from 'react';
import Header from '../components/Headerr';
import Hero from '../components/Hero';
import Keamanan from '../components/home/Keamanan';
import Penawaran from '../components/home/Penawaran';
import Galeri from '../components/home/Galeri';
import LinkWhatApp from '../components/LinkWhatApp';
import Footer from '../components/Footer';

const Home = () => {
  useEffect(() => {
    document.title = 'Azalea Student Living | Home ';
  }, []);
  return (
    <div>
      <div>
        <main class="main" id="top">
          <Header />
          <Hero />
          <Keamanan />
          <Penawaran />
          <Galeri />
          {/* <LinkWhatApp /> */}
          <Footer />
        </main>
      </div>
    </div>
  );
};

export default Home;
