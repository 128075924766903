import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './pages/Home';
import Peraturan from './pages/Peraturan';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home}></Route>
        <Route exact path="/peraturan" component={Peraturan}></Route>
      </Switch>
    </Router>
  );
}

export default App;
